import { TemperatureUnitType } from "./beerjson";
import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listCultures: build.query<ListCulturesApiResponse, ListCulturesApiArg>({
      query: () => ({ url: `/v1/cultures/` }),
      providesTags: ['Culture'],
    }),
    createCulture: build.mutation<
      CreateCultureApiResponse,
      CreateCultureApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/cultures/`,
        method: "POST",
        body: queryArg.record,
      }),
      invalidatesTags: ['Culture'],
    }),
    retrieveCulture: build.query<
      RetrieveCultureApiResponse,
      RetrieveCultureApiArg
    >({
      query: (queryArg) => ({ url: `/v1/cultures/${queryArg.id}/` }),
      providesTags: ['Culture'],
    }),
    updateCulture: build.mutation<
      UpdateCultureApiResponse,
      UpdateCultureApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/cultures/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.record,
      }),
      invalidatesTags: ['Culture'],
    }),
    partialUpdateCulture: build.mutation<
      PartialUpdateCultureApiResponse,
      PartialUpdateCultureApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/cultures/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.record,
      }),
      invalidatesTags: ['Culture'],
    }),
    destroyCulture: build.mutation<
      DestroyCultureApiResponse,
      DestroyCultureApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/cultures/${queryArg.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ['Culture'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListCulturesApiResponse = /** status 200  */ Culture[];
export type ListCulturesApiArg = void;
export type CreateCultureApiResponse = /** status 201  */ Culture;
export type CreateCultureApiArg = {
  record: Culture;
};
export type RetrieveCultureApiResponse = /** status 200  */ Culture;
export type RetrieveCultureApiArg = {
  id: string;
};
export type UpdateCultureApiResponse = /** status 200  */ Culture;
export type UpdateCultureApiArg = {
  id: string;
  record: Culture;
};
export type PartialUpdateCultureApiResponse = /** status 200  */ Culture;
export type PartialUpdateCultureApiArg = {
  id: string;
  record: Culture;
};
export type DestroyCultureApiResponse = unknown;
export type DestroyCultureApiArg = {
  id: string;
};
export type Culture = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  public?: boolean;
  name: string;
  type:
    | "ale"
    | "bacteria"
    | "brett"
    | "champagne"
    | "kveik"
    | "lacto"
    | "lager"
    | "malolactic"
    | "mixed-culture"
    | "other"
    | "pedio"
    | "spontaneous"
    | "wine";
  form: "liquid" | "dry" | "slant" | "culture" | "dregs";
  producer?: string | null;
  product_id?: string | null;
  temperature_min?: string | null;
  temperature_min_unit?: TemperatureUnitType | null;
  temperature_max?: string | null;
  temperature_max_unit?: TemperatureUnitType | null;
  alcohol_tolerance?: string | null;
  flocculation?: "very low" | "low" | "medium low" | "medium" | "medium high" | "high" | "very high" | null;
  attenuation_range_min?: string | null;
  attenuation_range_max?: string | null;
  notes?: string | null;
  best_for?: string | null;
  max_reuse?: number | null;
  pof?: boolean | null;
  glucoamylase?: boolean | null;
  zymocide_no1?: boolean | null;
  zymocide_no2?: boolean | null;
  zymocide_no28?: boolean | null;
  zymocide_klus?: boolean | null;
  zymocide_neutral?: boolean | null;
  overwrites?: string | null;
};
export const {
  useListCulturesQuery,
  useLazyListCulturesQuery,
  useCreateCultureMutation,
  useRetrieveCultureQuery,
  useLazyRetrieveCultureQuery,
  useUpdateCultureMutation,
  usePartialUpdateCultureMutation,
  useDestroyCultureMutation,
} = injectedRtkApi;
