import React from 'react';
import Typography from '@mui/material/Typography';
import { Redirect } from 'react-router-dom';


function LandingPageMobile() {
  return (
    
    <div>
      <Redirect to="/login" />
      <Typography variant="h1">
        Landing Page Mobile
      </Typography>
    </div>
  );
}

export default LandingPageMobile;
