

export const isNative = () => {
  return isCapacitorNative(window);
};

/**
 * @see https://github.com/ionic-team/ionic-framework/blob/master/core/src/utils/platform.ts
 */
type CapType = {
  isNative?: boolean
}

interface WinType extends Window {
  Capacitor?: CapType
}

const isCapacitorNative = (win: WinType): boolean => {
  const capacitor = win['Capacitor'];
  return !!capacitor?.isNative;
};


/*
const isCapacitorNative = (win: object): boolean => {
  //if(Object.keys(win).includes('Capacitor') && Object.keys(win['Capacitor']).includes('isNative')){
    const capacitor:  = win['Capacitor'].isNative;
    return !!(capacitor && capacitor.isNative);
  //}
  return false;
  
  
};
*/