import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listDevices: build.query<ListDevicesApiResponse, ListDevicesApiArg>({
      query: () => ({ url: `/v1/devices/` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListDevicesApiResponse = /** status 200  */ Device[];
export type ListDevicesApiArg = void;
export type Device = {
  id?: string;
  name?: string;
  apikey?: string;
  sensors?: {
    id?: number;
    name?: string;
    shortname: string;
    unit?: string;
    created_at?: string;
    updated_at?: string;
    taplist?: string | null;
    tap?: string | null;
  }[];
};
export const { useListDevicesQuery, useLazyListDevicesQuery } = injectedRtkApi;
