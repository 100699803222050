import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listHops: build.query<ListHopsApiResponse, ListHopsApiArg>({
      query: () => ({ url: `/v1/hops/` }),
      providesTags: ['Hop'],
    }),
    createHop: build.mutation<CreateHopApiResponse, CreateHopApiArg>({
      query: (queryArg) => ({
        url: `/v1/hops/`,
        method: "POST",
        body: queryArg.record,
      }),
      invalidatesTags: ['Hop'],
    }),
    retrieveHop: build.query<RetrieveHopApiResponse, RetrieveHopApiArg>({
      query: (queryArg) => ({ url: `/v1/hops/${queryArg.id}/` }),
      providesTags: ['Hop'],
    }),
    updateHop: build.mutation<UpdateHopApiResponse, UpdateHopApiArg>({
      query: (queryArg) => ({
        url: `/v1/hops/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.record,
      }),
      invalidatesTags: ['Hop'],
    }),
    partialUpdateHop: build.mutation<
      PartialUpdateHopApiResponse,
      PartialUpdateHopApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/hops/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.record,
      }),
      invalidatesTags: ['Hop'],
    }),
    destroyHop: build.mutation<DestroyHopApiResponse, DestroyHopApiArg>({
      query: (queryArg) => ({
        url: `/v1/hops/${queryArg.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ['Hop'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListHopsApiResponse = /** status 200  */ Hop[];
export type ListHopsApiArg = void;
export type CreateHopApiResponse = /** status 201  */ Hop;
export type CreateHopApiArg = {
  record: Hop;
};
export type RetrieveHopApiResponse = /** status 200  */ Hop;
export type RetrieveHopApiArg = {
  id: string;
};
export type UpdateHopApiResponse = /** status 200  */ Hop;
export type UpdateHopApiArg = {
  id: string;
  record: Hop;
};
export type PartialUpdateHopApiResponse = /** status 200  */ Hop;
export type PartialUpdateHopApiArg = {
  id: string;
  record: Hop;
};
export type DestroyHopApiResponse = unknown;
export type DestroyHopApiArg = {
  id: string;
};
export type Hop = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  public?: boolean;
  name: string;
  form?:
    | ("extract" | "leaf" | "leaf (wet)" | "pellet" | "powder" | "plug")
    | null;
  producer?: string | null;
  product_id?: string | null;
  use_for?: string | null;
  notes?: string | null;
  origin?: string | null;
  year?: string | null;
  alpha_acid?: string | null;
  beta_acid?: string | null;
  type?:
    | (
        | "aroma"
        | "bittering"
        | "flavor"
        | "aroma/bittering"
        | "bittering/flavor"
        | "aroma/flavor"
        | "aroma/bittering/flavor"
      )
    | null;
  percent_lost?: string | null;
  substitutes?: string | null;
  oil_content?: string | null;
  humulene?: string | null;
  caryophyllene?: string | null;
  cohumulone?: string | null;
  myrcene?: string | null;
  farnesene?: string | null;
  geraniol?: string | null;
  b_pinene?: string | null;
  linalool?: string | null;
  limonene?: string | null;
  nerol?: string | null;
  pinene?: string | null;
  polyphenols?: string | null;
  xanthohumol?: string | null;
  overwrites?: string | null;
};
export const {
  useListHopsQuery,
  useLazyListHopsQuery,
  useCreateHopMutation,
  useRetrieveHopQuery,
  useLazyRetrieveHopQuery,
  useUpdateHopMutation,
  usePartialUpdateHopMutation,
  useDestroyHopMutation,
} = injectedRtkApi;
