import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import LandingPageRoot from './landingPage/LandingPageRoot';
import Error from './pages/Error';
import Loading from './pages/Loading';
import Login from './login/Login';
import ComingSoon from './landingPage/web/ComingSoon';
import CssBaseline from '@mui/material/CssBaseline';

//import ApplicationRoot from './app/ApplicationRoot';
const ApplicationRoot = lazy(() => import('./app/ApplicationRoot'));

export default function MainRoute() {

  return (
    <>
      <CssBaseline />
      <Switch>
        <Route path="/signup">
          <ComingSoon />
        </Route>
        <Route path="/a">
          <Suspense fallback={<Loading/>}>
            <ApplicationRoot />
          </Suspense>
          
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <LandingPageRoot />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
    </>
  );
}
