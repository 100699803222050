import { VolumeUnitType, MassUnitType, UnitUnitType } from "./beerjson";
import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listMiscellaneous: build.query<
      ListMiscellaneousApiResponse,
      ListMiscellaneousApiArg
    >({
      query: () => ({ url: `/v1/miscellaneous/` }),
      providesTags: ['Miscellaneous'],
    }),
    createMiscellaneous: build.mutation<
      CreateMiscellaneousApiResponse,
      CreateMiscellaneousApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/miscellaneous/`,
        method: "POST",
        body: queryArg.record,
      }),
      invalidatesTags: ['Miscellaneous'],
    }),
    retrieveMiscellaneous: build.query<
      RetrieveMiscellaneousApiResponse,
      RetrieveMiscellaneousApiArg
    >({
      query: (queryArg) => ({ url: `/v1/miscellaneous/${queryArg.id}/` }),
      providesTags: ['Miscellaneous'],
    }),
    updateMiscellaneous: build.mutation<
      UpdateMiscellaneousApiResponse,
      UpdateMiscellaneousApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/miscellaneous/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.record,
      }),
      invalidatesTags: ['Miscellaneous'],
    }),
    partialUpdateMiscellaneous: build.mutation<
      PartialUpdateMiscellaneousApiResponse,
      PartialUpdateMiscellaneousApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/miscellaneous/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.record,
      }),
      invalidatesTags: ['Miscellaneous'],
    }),
    destroyMiscellaneous: build.mutation<
      DestroyMiscellaneousApiResponse,
      DestroyMiscellaneousApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/miscellaneous/${queryArg.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ['Miscellaneous'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListMiscellaneousApiResponse = /** status 200  */ Miscellaneous[];
export type ListMiscellaneousApiArg = void;
export type CreateMiscellaneousApiResponse = /** status 201  */ Miscellaneous;
export type CreateMiscellaneousApiArg = {
  record: Miscellaneous;
};
export type RetrieveMiscellaneousApiResponse = /** status 200  */ Miscellaneous;
export type RetrieveMiscellaneousApiArg = {
  id: string;
};
export type UpdateMiscellaneousApiResponse = /** status 200  */ Miscellaneous;
export type UpdateMiscellaneousApiArg = {
  id: string;
  record: Miscellaneous;
};
export type PartialUpdateMiscellaneousApiResponse =
  /** status 200  */ Miscellaneous;
export type PartialUpdateMiscellaneousApiArg = {
  id: string;
  record: Miscellaneous;
};
export type DestroyMiscellaneousApiResponse = unknown;
export type DestroyMiscellaneousApiArg = {
  id: string;
};
export type Miscellaneous = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  public?: boolean;
  name: string;
  type:
    | "spice"
    | "fining"
    | "water agent"
    | "herb"
    | "flavor"
    | "wood"
    | "other";
  producer?: string | null;
  product_id?: string | null;
  use_for?: string | null;
  notes?: string | null;
  overwrites?: string | null;
  amount_unit?: VolumeUnitType | MassUnitType | UnitUnitType | null;
};
export const {
  useListMiscellaneousQuery,
  useLazyListMiscellaneousQuery,
  useCreateMiscellaneousMutation,
  useRetrieveMiscellaneousQuery,
  useLazyRetrieveMiscellaneousQuery,
  useUpdateMiscellaneousMutation,
  usePartialUpdateMiscellaneousMutation,
  useDestroyMiscellaneousMutation,
} = injectedRtkApi;
