import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listKegs: build.query<ListKegsApiResponse, ListKegsApiArg>({
      query: () => ({ url: `/v1/kegs/` }),
    }),
    createKeg: build.mutation<CreateKegApiResponse, CreateKegApiArg>({
      query: (queryArg) => ({
        url: `/v1/kegs/`,
        method: "POST",
        body: queryArg.keg,
      }),
    }),
    retrieveKeg: build.query<RetrieveKegApiResponse, RetrieveKegApiArg>({
      query: (queryArg) => ({ url: `/v1/kegs/${queryArg.id}/` }),
    }),
    updateKeg: build.mutation<UpdateKegApiResponse, UpdateKegApiArg>({
      query: (queryArg) => ({
        url: `/v1/kegs/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.keg,
      }),
    }),
    partialUpdateKeg: build.mutation<
      PartialUpdateKegApiResponse,
      PartialUpdateKegApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/kegs/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.keg,
      }),
    }),
    destroyKeg: build.mutation<DestroyKegApiResponse, DestroyKegApiArg>({
      query: (queryArg) => ({
        url: `/v1/kegs/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListKegsApiResponse = /** status 200  */ Keg[];
export type ListKegsApiArg = void;
export type CreateKegApiResponse = /** status 201  */ Keg;
export type CreateKegApiArg = {
  keg: Keg;
};
export type RetrieveKegApiResponse = /** status 200  */ Keg;
export type RetrieveKegApiArg = {
  id: string;
};
export type UpdateKegApiResponse = /** status 200  */ Keg;
export type UpdateKegApiArg = {
  id: string;
  keg: Keg;
};
export type PartialUpdateKegApiResponse = /** status 200  */ Keg;
export type PartialUpdateKegApiArg = {
  id: string;
  keg: Keg;
};
export type DestroyKegApiResponse = unknown;
export type DestroyKegApiArg = {
  id: string;
};
export type Keg = {
  id?: string;
  name?: string;
  tap?: string | null;
  abv?: string;
  ibu?: string;
  og?: string;
  fg?: string;
  color?: string;
  description?: string;
  active_tap: string[];
  created_at?: string;
  updated_at?: string;
};
export const {
  useListKegsQuery,
  useLazyListKegsQuery,
  useCreateKegMutation,
  useRetrieveKegQuery,
  useLazyRetrieveKegQuery,
  useUpdateKegMutation,
  usePartialUpdateKegMutation,
  useDestroyKegMutation,
} = injectedRtkApi;
