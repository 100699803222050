import React from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export default function Loading() {
  return (

  <div style={{
    minWidth: "100vh",
    minHeight: "100vh", 
    textAlign: "center", 
    paddingTop: '45vh', 
    //backgroundImage: 'url("/assets/img/login-bg-small.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%' 
    }}>
      <Box>
        <CircularProgress />
        <Typography variant="h1">
          Loading
        </Typography>
      </Box>
    </div>
  );
}
