import { createTheme } from '@mui/material/styles';


export const theme = createTheme({
  palette: {
    mode: 'light',
    error: {
      light: '#FF5252',
      main: '#FF5252',
      dark: '#FF5252',
    },
    primary: {
      light: '#CFD8DC',
      main: '#607D8B',
      dark: '#455A64',
    },
    /*
    secondary: {
      light: '#B6B6B6',
      main: '#727272',
      dark: '#212121',
    },
    */
    secondary: {
      light: '#FF5252',
      main: '#FF5252',
      dark: '#FF5252',
    },
    text: {
      primary: 'rgba(21, 27, 30, .87)',
      secondary: 'rgba(21, 27, 30, .59)',
      disabled: 'rgba(21, 27, 30, .43)',
      //hint: 'rgba(21, 27, 30, .17)',
    },
    background: {
      default: "#EAEAEA"
    }
  },
  /*
  palette: {
    type: 'light',
    action: {
      active: 'rgb(245,250,251)',
    },
    primary: {
      light: '#30EDB2',
      main: '#1CB075',
      dark: '#027247',
      contrastText: 'rgb(245,250,251)',
    },
    secondary: {
      light: '#ffc947',
      main: '#ff9800',
      dark: '#c66900',
      contrastText: '#000',
    },
    */
  /*
    background: {
      paper: '#303038',
      default: '#2D2D35',
    },
    */
  /*
    divider: 'rgb(35, 35, 42)',
  },
  */
  typography: {
    //useNextVariants: true,
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    body2: {
      // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      letterSpacing: '0.04em',
    },
    body1: {
      // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontSize: '1.6rem',
    },
    h3: {
      fontSize: '1.85rem',
      lineHeight: '1.2em',
    },
    h2: {
      fontSize: '2.125rem',
    },
    h1: {
      fontSize: '2.8215rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  /*
    display4: {
      fontSize: '6rem',
      fontWeight: 300,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // letterSpacing: '-0.4em',
      // lineHeight: '1.14286em',
      // marginLeft: '-.04em',
      // color: 'rgba(255, 255, 255, 0.54)',
      color: 'rgb(245,250,251)',
    },
    display3: {
      fontSize: '3.75rem',
      fontWeight: 300,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // letterSpacing: '-0.2em',
      // lineHeight: '1.30357em',
      // marginLeft: '-.02em',
      // color: 'rgba(255, 255, 255, 0.54)',
      color: 'rgb(245,250,251)',
    },
    display2: {
      fontSize: '2.125rem',
      fontWeight: 400,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // lineHeight: '1.06667em',
      // marginLeft: '-.02em',
      // color: 'rgba(255, 255, 255, 0.54)',
      color: 'rgb(245,250,251)',
    },
    display1: {
      fontSize: '1.5rem',
      fontWeight: 400,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // lineHeight: '1.20588em',
      // color: 'rgba(255, 255, 255, 0.54)',
      color: 'rgb(245,250,251)',
    },
    headline: {
      fontSize: '1.25rem',
      fontWeight: 500,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // lineHeight: '1.35417em',
      // color: 'rgba(255, 255, 255, 0.87)',
      color: 'rgb(245,250,251)',
    },
    title: {
      fontSize: '1rem',
      fontWeight: 400,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // lineHeight: '1.35417em',
      // color: 'rgba(255, 255, 255, 0.87)',
      color: 'rgb(245,250,251)',
    },
    subheading: {
      fontSize: '0.875rem',
      fontWeight: 400,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // lineHeight: '1.5em',
      // color: 'rgba(255, 255, 255, 0.87)',
      color: 'rgb(245,250,251)',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 300,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // lineHeight: '1.71429em',
      // color: 'rgba(255, 255, 255, 0.87)',
      color: 'rgb(245,250,251)',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 300,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // lineHeight: '1.46429em',
      // color: 'rgba(255, 255, 255, 0.87)',
      color: 'rgb(245,250,251)',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.375em',
      // color: 'rgba(255, 255, 255, 0.54)',
      color: 'rgb(245,250,251)',
    },
    button: {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      // color: 'rgba(255, 255, 255, 0.87)',
      color: 'rgb(245,250,251)',
    },
  },
  */
 /*
  overrides: {
    MuiPaper: {
      root: {
        // border: '#DDD',
        border: '#D0D6D8',
        borderWidth: '1px',
        borderStyle: 'solid',
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        letterSpacing: '0.06em',
      },
    },
  },
  props: {
    MuiPaper: {
      square: true,
      elevation: 0,
    },
  },
  */
});

//export const theme = responsiveFontSizes(themeNoFont);
