import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
//import { coreApi } from './coreApi'
import { bnApi as cultureApi } from './cultures'
//import { bnApi as datapointApi } from './datapoint'
import { bnApi as devicesApi } from './devices'
import { bnApi as fermentablesApi } from './fermentables'
import { bnApi as hopsApi } from './hops'
import { bnApi as kegsApi } from './kegs'
import { bnApi as miscellaneousApi } from './miscellaneous'
import { bnApi as serverApi } from './server'
import { bnApi as stylesApi } from './styles'
import { bnApi as taplistsApi } from './taplists'
import { bnApi as tokenApi } from './token'
import { bnApi as waterApi } from './water'
import { emptySplitApi } from './emptyApi'
import { combineReducers } from "redux"
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";

import auth from './authSlice'

const reducers = combineReducers({
  [cultureApi.reducerPath]: cultureApi.reducer,
//  [datapointApi.reducerPath]: datapointApi.reducer,
  [devicesApi.reducerPath]: devicesApi.reducer,
  [fermentablesApi.reducerPath]: fermentablesApi.reducer,
  [hopsApi.reducerPath]: hopsApi.reducer,
  [kegsApi.reducerPath]: kegsApi.reducer,
  [miscellaneousApi.reducerPath]: miscellaneousApi.reducer,
  [serverApi.reducerPath]: serverApi.reducer,
  [stylesApi.reducerPath]: stylesApi.reducer,
  [taplistsApi.reducerPath]: taplistsApi.reducer,
  [tokenApi.reducerPath]: tokenApi.reducer,
  [waterApi.reducerPath]: waterApi.reducer,
  //[coreApi.reducerPath]: coreApi.reducer,
  auth,
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(emptySplitApi.middleware),
    /*.concat(cultureApi.middleware)
      .concat(datapointApi.middleware)
      .concat(devicesApi.middleware)
      .concat(fermentablesApi.middleware)
      .concat(hopsApi.middleware)
      .concat(kegsApi.middleware)
      .concat(miscellaneousApi.middleware)
      .concat(serverApi.middleware)
      .concat(stylesApi.middleware)
      .concat(taplistsApi.middleware)
      .concat(tokenApi.middleware)
      .concat(waterApi.middleware), */


})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
//export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof reducers>;