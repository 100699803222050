import React from 'react';
//import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';

type SectionProps = {
  color?: string;
  children?: React.ReactElement | React.ReactElement[];
  variant?: "solid" | "grid" | "image";
  minHeight?: string | number;
  imgUrl?: string;
};

/*
background-color: #e5e5f7;
opacity: 0.8;
background-image:  linear-gradient(#444cf7 2px, transparent 2px), linear-gradient(90deg, #444cf7 2px, transparent 2px), linear-gradient(#444cf7 1px, transparent 1px), linear-gradient(90deg, #444cf7 1px, #e5e5f7 1px);
background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
*/

/*
display: "block",
width: "100%",
height: "100%",
object-fit: "cover",
object-position: "center",
position: re"lative,
left: "50%",
transform: "translateX(-50%)",
filter: "brightness(60%)",
user-select: "none",
    */

function Section({ color, children, variant = "solid", minHeight = "20em", imgUrl }: SectionProps) {
  const theme = useTheme();
  
  const solidStyle = {
    backgroundColor: color || theme.palette.background.default,
    color: theme.palette.getContrastText(color || theme.palette.background.default),
    minHeight: minHeight,
    paddingTop: '4em',
    paddingBottom: '4em',
  }

  /*
  const imageStyle = {
    backgroundImage: 'url(' + imgUrl + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
    /*
    backgroundColor: color || theme.palette.background.default,
    color: theme.palette.getContrastText(color || theme.palette.background.default),
    backgroundImage: 'url("assets/img/login-bg-small.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    minHeight: "400px",
    maxHeight: "900px",
    overflow: "hidden",
    */
//  }


  const gridStyle = {
    minHeight: '20em',
    paddingTop: '4em',
    paddingBottom: '4em',
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'linear-gradient(' + color +' 2px, transparent 2px), linear-gradient(90deg, ' + color + ' 2px, transparent 2px), linear-gradient(' + color + ' 1px, transparent 1px), linear-gradient(90deg, ' + color + ' 1px, ' + theme.palette.background.default + ' 1px)',
    backgroundSize: '50px 50px, 50px 50px, 10px 10px, 10px 10px',
    backgroundPosition: '-2px - 2px, -2px - 2px, -1px - 1px, -1px - 1px',
  }
  if (variant === "grid") {
    return (
      <div style={gridStyle}>
        <Container maxWidth="lg">
          {children}
        </Container>
      </div>
    )
  } else if (variant === "image") {
    return (
      <div>
        <div style={{
        backgroundImage: 'url(' + imgUrl + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: '100vh',
        maxHeight: '900px',
        minHeight: '400px',
        backgroundColor: '#555',
        backgroundBlendMode: "multiply",
        color: theme.palette.getContrastText("#555"),
        textShadow: "0 1px 40px #000",
        }}>
          <Container maxWidth="lg" style={{
            paddingTop: '30vh',
            height: '100vh',
            maxHeight: '900px',
            minHeight: '400px',
          }}>
            {children}
          </Container>
          </div>
      </div>
    )
  }
  return (
    <div style={solidStyle}>
      <Container maxWidth="lg">
        {children}
      </Container>
    </div>
  );
}

export default Section;

/*
background-color: #e5e5f7;
opacity: 0.8;
background-image:  linear-gradient(#444cf7 2px, transparent 2px), linear-gradient(90deg, #444cf7 2px, transparent 2px), linear-gradient(#444cf7 1px, transparent 1px), linear-gradient(90deg, #444cf7 1px, #e5e5f7 1px);
background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
*/