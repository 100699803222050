import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FullLogo from '../icon/fulllogo';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Redirect } from 'react-router-dom';
import { selectIsLoggedIn } from '../store/authSlice';
import { setCredentials } from '../store/authSlice';
import { useCreateTokenObtainPairMutation } from '../store/token';


function Login() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();
  const [creds, setCreds] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [createToken, { isLoading: isUpdating }] = useCreateTokenObtainPairMutation();

  const login = async () => {
    setError('');
    createToken({ tokenObtainPair: creds }).unwrap().then(payload => {
      dispatch(setCredentials({ user: {}, token: payload.access ? payload.access : '', refreshToken: payload.refresh }));
      history.push("/a");
    }).catch(rejected => {
      if (rejected.status === 401 || rejected.status === 400) {
        setError("Incorrect Email or Password");
      }
    })
  }

  return (
    <>
      {isLoggedIn ? <Redirect to="/a" /> : null}
      <div style={{
        backgroundImage: 'url("assets/img/login-bg-small.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'

      }}>
        <Modal
          open={true}
          //onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ minHeight: '100%' }}
        >
          <div>
            <Paper sx={{
              maxWidth: '400px',
              width: '100%',
              margin: 0,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '16px'

            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 'fit-content' }}>
                    <FullLogo />
                  </div></Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ color: 'error.main' }}>{error}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Email" variant="filled" type="email" value={creds.email} onChange={(e) => setCreds({ ...creds, email: e.target.value })} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Password" variant="filled" type="password" value={creds.password} onChange={(e) => setCreds({ ...creds, password: e.target.value })} onKeyPress={(e) => e.key === 'Enter' && login()} />
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" disabled={isUpdating} onClick={login}>Log In</Button>
                </Grid>
                <Grid item xs={6} sx={{ "textAlign": "end" }}>
                  <Button variant="outlined">Create Account</Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Modal >
      </div >
    </>
  );
}

export default Login;
