import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listServerVersions: build.query<
      ListServerVersionsApiResponse,
      ListServerVersionsApiArg
    >({
      query: () => ({ url: `/v1/server_version/` }),
    }),
  }),
  overrideExisting: false,
});


export { injectedRtkApi as bnApi };
export type ListServerVersionsApiResponse = /** status 200  */{
  version: string | null
};
export type ListServerVersionsApiArg = void;
export const { useListServerVersionsQuery, useLazyListServerVersionsQuery } =
  injectedRtkApi;
