import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listStyles: build.query<ListStylesApiResponse, ListStylesApiArg>({
      query: () => ({ url: `/v1/styles/` }),
    }),
    createStyle: build.mutation<CreateStyleApiResponse, CreateStyleApiArg>({
      query: (queryArg) => ({
        url: `/v1/styles/`,
        method: "POST",
        body: queryArg.style,
      }),
    }),
    retrieveStyle: build.query<RetrieveStyleApiResponse, RetrieveStyleApiArg>({
      query: (queryArg) => ({ url: `/v1/styles/${queryArg.id}/` }),
    }),
    updateStyle: build.mutation<UpdateStyleApiResponse, UpdateStyleApiArg>({
      query: (queryArg) => ({
        url: `/v1/styles/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.style,
      }),
    }),
    partialUpdateStyle: build.mutation<
      PartialUpdateStyleApiResponse,
      PartialUpdateStyleApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/styles/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.style,
      }),
    }),
    destroyStyle: build.mutation<DestroyStyleApiResponse, DestroyStyleApiArg>({
      query: (queryArg) => ({
        url: `/v1/styles/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListStylesApiResponse = /** status 200  */ Style[];
export type ListStylesApiArg = void;
export type CreateStyleApiResponse = /** status 201  */ Style;
export type CreateStyleApiArg = {
  style: Style;
};
export type RetrieveStyleApiResponse = /** status 200  */ Style;
export type RetrieveStyleApiArg = {
  id: string;
};
export type UpdateStyleApiResponse = /** status 200  */ Style;
export type UpdateStyleApiArg = {
  id: string;
  style: Style;
};
export type PartialUpdateStyleApiResponse = /** status 200  */ Style;
export type PartialUpdateStyleApiArg = {
  id: string;
  style: Style;
};
export type DestroyStyleApiResponse = unknown;
export type DestroyStyleApiArg = {
  id: string;
};
export type Style = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  public?: boolean;
  name: string;
  category: string;
  category_number?: number | null;
  style_letter: string;
  style_guide: string;
  type: "beer" | "cider" | "kombucha" | "mead" | "other" | "soda" | "wine";
  original_gravity_min?: string | null;
  original_gravity_min_unit?: string | null;
  original_gravity_max?: string | null;
  original_gravity_max_unit?: string | null;
  final_gravity_min?: string | null;
  final_gravity_min_unit?: string | null;
  final_gravity_max?: string | null;
  final_gravity_max_unit?: string | null;
  international_bitterness_units_min?: string | null;
  international_bitterness_units_min_unit?: string | null;
  international_bitterness_units_max?: string | null;
  international_bitterness_units_max_unit?: string | null;
  color_min?: string | null;
  color_min_unit?: string | null;
  color_max?: string | null;
  color_max_unit?: string | null;
  alcohol_by_volume_min?: string | null;
  alcohol_by_volume_max?: string | null;
  notes?: string | null;
  aroma?: string | null;
  appearance?: string | null;
  flavor?: string | null;
  mouthfeel?: string | null;
  overall_impression?: string | null;
  ingredients?: string | null;
  examples?: string | null;
  link?: string | null;
  overwrites?: string | null;
};
export const {
  useListStylesQuery,
  useLazyListStylesQuery,
  useCreateStyleMutation,
  useRetrieveStyleQuery,
  useLazyRetrieveStyleQuery,
  useUpdateStyleMutation,
  usePartialUpdateStyleMutation,
  useDestroyStyleMutation,
} = injectedRtkApi;
