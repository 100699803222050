import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Section from './Section';
import LogoWide from '../../icon/logo-wide';


function Footer() {
  const theme = useTheme();
  return (
    <Section color={theme.palette.primary.dark}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
          <LogoWide color={theme.palette.primary.contrastText} />
          <Typography variant="subtitle1" align="center">
            ©2022 BrewingNerd, LLC
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="subtitle1" color="secondary" sx={{ paddingBottom: 2 }}>
            <strong>Product</strong>
          </Typography>
          <Typography 
            component={Link} 
            to="/signup" 
            variant="subtitle1" 
            sx={{
              display: 'block',
              color: 'inherit',
              textDecoration: 'none', 
              paddingBottom: 2,
              '&:hover': {
                textDecoration: 'underline'
              },
            }}
          >
            Google Play Store
          </Typography>
          <Typography
            component={Link}
            to="/signup"
            variant="subtitle1"
            sx={{
              display: 'block',
              color: 'inherit',
              textDecoration: 'none',
              paddingBottom: 2,
              '&:hover': {
                textDecoration: 'underline'
              },
            }}>
            Apple App Store
          </Typography>
          <Typography
            component={Link}
            to="/signup"
            variant="subtitle1"
            sx={{
              display: 'block',
              color: 'inherit',
              textDecoration: 'none',
              paddingBottom: 2,
              '&:hover': {
                textDecoration: 'underline'
              },
            }}
          >
            Desktop
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="subtitle1" color="secondary" sx={{ paddingBottom: 2 }}>
            <strong>Company</strong>
          </Typography>
          <Typography
            component={Link}
            to="/signup"
            variant="subtitle1"
            sx={{
              display: 'block',
              color: 'inherit',
              textDecoration: 'none',
              paddingBottom: 2,
              '&:hover': {
                textDecoration: 'underline'
              },
            }}
          >
            Contact
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: "none", sm: "block", md: "none" } }}></Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="subtitle1" color="secondary" sx={{ paddingBottom: 2 }}>
            <strong>Resources</strong>
          </Typography>
          <Typography
            component={Link}
            to="/signup"
            variant="subtitle1"
            sx={{
              display: 'block',
              color: 'inherit',
              textDecoration: 'none',
              paddingBottom: 2,
              '&:hover': {
                textDecoration: 'underline'
              },
            }}
          >
            Developers
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Typography variant="subtitle1" color="secondary" sx={{ paddingBottom: 2 }}>
            <strong>Policies</strong>
          </Typography>
          <Typography
            component={Link}
            to="/signup"
            variant="subtitle1"
            sx={{
              display: 'block',
              color: 'inherit',
              textDecoration: 'none',
              paddingBottom: 2,
              '&:hover': {
                textDecoration: 'underline'
              },
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            component={Link}
            to="/signup"
            variant="subtitle1"
            sx={{
              display: 'block',
              color: 'inherit',
              textDecoration: 'none',
              paddingBottom: 2,
              '&:hover': {
                textDecoration: 'underline'
              },
            }}
          >
            Terms
          </Typography>
        </Grid>
      </Grid>
    </Section>
  );
}

export default Footer;
