import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listWaters: build.query<ListWatersApiResponse, ListWatersApiArg>({
      query: () => ({ url: `/v1/water/` }),
      providesTags: ['Water'],
    }),
    createWater: build.mutation<CreateWaterApiResponse, CreateWaterApiArg>({
      query: (queryArg) => ({
        url: `/v1/water/`,
        method: "POST",
        body: queryArg.record,
        invalidatesTags: ['Water'],
      }),
    }),
    retrieveWater: build.query<RetrieveWaterApiResponse, RetrieveWaterApiArg>({
      query: (queryArg) => ({ url: `/v1/water/${queryArg.id}/` }),
      providesTags: ['Water'],
    }),
    updateWater: build.mutation<UpdateWaterApiResponse, UpdateWaterApiArg>({
      query: (queryArg) => ({
        url: `/v1/water/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.record,
      }),
      invalidatesTags: ['Water'],
    }),
    partialUpdateWater: build.mutation<
      PartialUpdateWaterApiResponse,
      PartialUpdateWaterApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/water/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.record,
      }),
      invalidatesTags: ['Water'],
    }),
    destroyWater: build.mutation<DestroyWaterApiResponse, DestroyWaterApiArg>({
      query: (queryArg) => ({
        url: `/v1/water/${queryArg.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ['Water'],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListWatersApiResponse = /** status 200  */ Water[];
export type ListWatersApiArg = void;
export type CreateWaterApiResponse = /** status 201  */ Water;
export type CreateWaterApiArg = {
  record: Water;
};
export type RetrieveWaterApiResponse = /** status 200  */ Water;
export type RetrieveWaterApiArg = {
  id: string;
};
export type UpdateWaterApiResponse = /** status 200  */ Water;
export type UpdateWaterApiArg = {
  id: string;
  record: Water;
};
export type PartialUpdateWaterApiResponse = /** status 200  */ Water;
export type PartialUpdateWaterApiArg = {
  id: string;
  record: Water;
};
export type DestroyWaterApiResponse = unknown;
export type DestroyWaterApiArg = {
  id: string;
};
export type Water = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  public?: boolean;
  name: string;
  producer?: string | null;
  calcium: string;
  calcium_unit: string;
  bicarbonate: string;
  bicarbonate_unit: string;
  carbonate?: string | null;
  carbonate_unit?: string | null;
  potassium?: string | null;
  potassium_unit?: string | null;
  iron?: string | null;
  iron_unit?: string | null;
  nitrate?: string | null;
  nitrate_unit?: string | null;
  nitrite?: string | null;
  nitrite_unit?: string | null;
  flouride?: string | null;
  flouride_unit?: string | null;
  sulfate: string;
  sulfate_unit: string;
  chloride: string;
  chloride_unit: string;
  sodium: string;
  sodium_unit: string;
  magnesium: string;
  magnesium_unit: string;
  notes?: string | null;
  ph?: string | null;
  overwrites?: string | null;
};
export const {
  useListWatersQuery,
  useLazyListWatersQuery,
  useCreateWaterMutation,
  useRetrieveWaterQuery,
  useLazyRetrieveWaterQuery,
  useUpdateWaterMutation,
  usePartialUpdateWaterMutation,
  useDestroyWaterMutation,
} = injectedRtkApi;
