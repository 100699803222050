import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listTaplists: build.query<ListTaplistsApiResponse, ListTaplistsApiArg>({
      query: () => ({ url: `/v1/taplists/` }),
    }),
    createTaplist: build.mutation<
      CreateTaplistApiResponse,
      CreateTaplistApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/taplists/`,
        method: "POST",
        body: queryArg.taplist,
      }),
    }),
    retrieveTaplist: build.query<
      RetrieveTaplistApiResponse,
      RetrieveTaplistApiArg
    >({
      query: (queryArg) => ({ url: `/v1/taplists/${queryArg.id}/` }),
    }),
    updateTaplist: build.mutation<
      UpdateTaplistApiResponse,
      UpdateTaplistApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/taplists/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.taplist,
      }),
    }),
    partialUpdateTaplist: build.mutation<
      PartialUpdateTaplistApiResponse,
      PartialUpdateTaplistApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/taplists/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.taplist,
      }),
    }),
    destroyTaplist: build.mutation<
      DestroyTaplistApiResponse,
      DestroyTaplistApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/taplists/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListTaplistsApiResponse = /** status 200  */ Taplist[];
export type ListTaplistsApiArg = void;
export type CreateTaplistApiResponse = /** status 201  */ Taplist;
export type CreateTaplistApiArg = {
  taplist: Taplist;
};
export type RetrieveTaplistApiResponse = /** status 200  */ Taplist;
export type RetrieveTaplistApiArg = {
  id: string;
};
export type UpdateTaplistApiResponse = /** status 200  */ Taplist;
export type UpdateTaplistApiArg = {
  id: string;
  taplist: Taplist;
};
export type PartialUpdateTaplistApiResponse = /** status 200  */ Taplist;
export type PartialUpdateTaplistApiArg = {
  id: string;
  taplist: Taplist;
};
export type DestroyTaplistApiResponse = unknown;
export type DestroyTaplistApiArg = {
  id: string;
};
export type Taplist = {
  id?: string;
  name?: string;
  title?: string;
  is_public?: boolean;
  public_id?: string;
  owner: string;
  created_at?: string;
  updated_at?: string;
  taps?: {
    id?: number;
    name?: string;
    order?: number;
    active_keg?: string | null;
    created_at?: string;
    updated_at?: string;
  }[];
};
export const {
  useListTaplistsQuery,
  useLazyListTaplistsQuery,
  useCreateTaplistMutation,
  useRetrieveTaplistQuery,
  useLazyRetrieveTaplistQuery,
  useUpdateTaplistMutation,
  usePartialUpdateTaplistMutation,
  useDestroyTaplistMutation,
} = injectedRtkApi;
