import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { RootState } from "./store";



const slice = createSlice({
  name: "auth",
  initialState: { user: null, token: null, refreshToken: null } as {
    user: null | object;
    token: null | string;
    refreshToken: null | string;
  },
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token, refreshToken } }: PayloadAction<{ user: object; token: string; refreshToken: string }>
    ) => {
      state.user = user;
      state.token = token;
      state.refreshToken = refreshToken;
    },
    tokenReceived: (
      state,
      { payload }: PayloadAction<{ access: string, refresh: string}>
    ) => {
      state.token = payload.access;
      state.refreshToken = payload.refresh;
    },
    logOut: (
      state
    ) => {
      state.token = null;
      state.refreshToken = null;
    },
  },
  //extraReducers: (builder) => { }
});

export const { setCredentials, tokenReceived, logOut } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectIsLoggedIn = (state: RootState) => state.auth.token == null ? false : true;
export const tokenNeedsRefresh = (state: RootState) => {
  if (state.auth.token) {
    const REFRESH_SECONDS = 60;
    const payload = jwtDecode<JwtPayload>(state.auth.token);
    // token is already expired or missing
    if (!payload.exp || ((payload.exp - Math.floor(Date.now() / 1000)) < REFRESH_SECONDS )) {
      return true;
    }
    return false;
  }
  // token doesn't exist
  return true;
}
