import React from 'react';
//import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <g>
    <path d="M9.44 1.58h3.23A1.28 1.28 0 0 1 14.13 3v2c0 .61-.19 1-.56 1.11.37.14.56.48.56 1v2.14a1.28 1.28 0 0 1-1.46 1.43H9.44zm3.16 3.51V3.18a.48.48 0 0 0-.08-.31.48.48 0 0 0-.31-.08H11v2.69h1.23a.48.48 0 0 0 .31-.08.48.48 0 0 0 .06-.31zm0 4v-2a.44.44 0 0 0-.08-.31.43.43 0 0 0-.31-.08H11v2.77h1.23a.48.48 0 0 0 .31-.08.46.46 0 0 0 .06-.31zM13 13.35h1.51v9.1H13l-2-6v6H9.48v-9.1h1.6L13 19.29zM5.32 3.55v5.78C5.32 11 5 11.85 4.24 12c.72.15 1.08 1 1.08 2.63v5.82a1.16 1.16 0 0 0 .1.57.44.44 0 0 0 .39.16h.72v1.31h-1.2a.78.78 0 0 1-.78-.49 3 3 0 0 1-.21-1.29v-5.52A4.55 4.55 0 0 0 4 13.24a1 1 0 0 0-1-.62h-.21v-1.28h.28a1 1 0 0 0 1-.62 4.6 4.6 0 0 0 .31-2V3.25A3.1 3.1 0 0 1 4.55 2a.8.8 0 0 1 .78-.43h1.2v1.26h-.72a.44.44 0 0 0-.39.17 1.12 1.12 0 0 0-.1.55zM18.37 9.33V3.55a1 1 0 0 0-.11-.55.42.42 0 0 0-.38-.16h-.72V1.52h1.2a.8.8 0 0 1 .78.43 3.1 3.1 0 0 1 .21 1.3v5.5a4.6 4.6 0 0 0 .31 2 1 1 0 0 0 1 .62h.28v1.28h-.28a1 1 0 0 0-1 .62 4.55 4.55 0 0 0-.31 1.95v5.55a3 3 0 0 1-.21 1.23.79.79 0 0 1-.78.44h-1.2v-1.28h.72a.42.42 0 0 0 .38-.16 1 1 0 0 0 .11-.57v-5.82c0-1.6.36-2.48 1.08-2.63-.72-.13-1.08-.98-1.08-2.65zM6.8 11.43h10.27v.8H6.8z" />
  </g>,
  'LogoIcon',
);
