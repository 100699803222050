import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors'

const InverseButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey["50"]),
  backgroundColor: blueGrey["50"],
  '&:hover': {
    backgroundColor: blueGrey["A100"],
  },
}));

export default InverseButton;