import React from 'react';
import Typography from '@mui/material/Typography';
import LandingAppBar from './LandingAppBar';
import { useTheme } from '@mui/material/styles';
import { useHistory, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Footer from './Footer';
import Section from './Section';
import Container from '@mui/material/Container';
import DownloadIcon from '@mui/icons-material/Download';
import InverseButton from './InverseButton';


function LandingPageWeb() {
  const theme = useTheme();
  const history = useHistory();
  return (
    <>
      <LandingAppBar />
      <Section variant="image" color={theme.palette.primary.main} imgUrl="/assets/img/login-bg-small.jpg">
        <Typography variant="h1" align="center" sx={{ paddingY: 4 }}>
          <strong>Simplify your brewing</strong>
        </Typography>
        <Typography variant="h6" align="center">
          BrewingNerd is your single pane of glass to manage your brewing.  From building recipes to displaying your tap lists, Brewing<strong>Nerd</strong> helps keep track of your inventory and lets plan your brew day in advance.
        </Typography>
        <Container sx={{ padding: 2, textAlign: "center" }}>
          <Button sx={{ margin: 2 }} component={Link} to="/signup" size="large" variant="contained" color="secondary">Create an Account</Button>
          <InverseButton onClick={() => history.push("/signup")} size="large" ><DownloadIcon/>&nbsp;Get the App</InverseButton>
        </Container>
      </Section>
      <Section color={theme.palette.primary.main}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <img style={{ width: "100%"}} src='/assets/img/brewingstock.jpg' alt="Homebrewing" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h1" align="center">
              <strong>
                Do it all here
              </strong>
            </Typography>
            <Typography variant="h6" align="center">
              Seamlessly move between the desktop application, web interface, and mobile application.  Quickly migrate your existing BeerXML and BeerJSON recipes into BrewingNerd.
            </Typography>
          </Grid>
        </Grid>
        
      </Section>
      <Section variant="grid" color={theme.palette.primary.light}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={4}>
            <Paper sx={{ padding: 2, textAlign: 'center' }}>
              <Typography variant="h4" align="center" sx={{ paddingBottom: 2 }}>
                <strong>Free Trial</strong>
              </Typography>
              <Typography variant="h2" align="center" sx={{ paddingBottom: 2 }}>
                $0
              </Typography>
              <Typography variant="body1" align="center" sx={{ paddingBottom: 2 }}>
                - 30 day premium free trial then 5 recipe storage
                
              </Typography>
              <Button size="large" component={Link} to="/signup" variant="contained">Start Free Trial</Button>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper sx={{ padding: 2, textAlign: 'center' }}>
              <Typography variant="h4" align="center" sx={{ paddingBottom: 2 }}>
                <strong>Premium</strong>
              </Typography>
              <Typography variant="h2" align="center" sx={{ paddingBottom: 2 }}>
                $5<small>/month</small>
              </Typography>
              <Typography variant="body1" align="center" sx={{ paddingBottom: 2 }}>
                - Unlimited Recipe Storage <br/>
                - Taplist Displays
              </Typography>
              <Button size="large" component={Link} to="/signup" variant="contained">Sign Up Now</Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper sx={{ padding: 2, textAlign: 'center' }}>
              <Typography variant="h4" align="center" sx={{ paddingBottom: 2 }}>
                <strong>Annual</strong>
              </Typography>
              <Typography variant="h2" align="center" sx={{ paddingBottom: 2 }}>
                $30<small>/year</small>
              </Typography>
              <Typography variant="body1" align="center" sx={{ paddingBottom: 2 }}>
                - All the premium features<br/>
                - Save by buying a yearly subscription

              </Typography>
              <Button size="large" component={Link} to="/signup" variant="contained">Sign Up Now</Button>
            </Paper>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Typography variant="h1" align="center" sx={{ paddingBottom: 2 }}>
          <strong>Ready to revolutionize your brewing?</strong>
        </Typography>
        <Container sx={{ padding: 2, textAlign: "center" }}>
          <Button size="large" component={Link} to="/signup" variant="contained" color="secondary">Create an Account</Button>
        </Container>
      </Section>
      <Footer />
    </>
  );
}

export default LandingPageWeb;


