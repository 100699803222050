import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button, { ButtonProps } from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from '../../store/authSlice';
import LogoIcon from '../../icon/logo';
import { blueGrey } from '@mui/material/colors'
import useScrollTrigger from '@mui/material/useScrollTrigger';


const AppBarButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey["50"]),
  backgroundColor: blueGrey["50"],
  textDecoration: "none",
  '&:hover': {
    backgroundColor: blueGrey["A100"],
  },
}));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}


const pages = ['Download', 'Features', 'Pricing'];

function ResponsiveAppBar() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <ElevationScroll>
      <AppBar position="fixed" style={{ backdropFilter: "brightness(.6) blur(12px) opacity(1)", backgroundColor: "unset"}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <LogoIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
                fontVariant: 'small-caps'
              }}
            >
              Brewing<strong>Nerd</strong>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >

                {isLoggedIn ? (
                  <MenuItem component={Link} to='/a' onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Open Dashboard</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem component={Link} to='/login' onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Log In</Typography>
                  </MenuItem>
                )}

                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <LogoIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Brewing<strong>Nerd</strong>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Typography
                  key={page}
                  variant="h5"
                  noWrap
                  component={Link}
                  to="/"
                  sx={{
                    mr: 4,
                    letterSpacing: '.1rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  <small>{page}</small>
                </Typography>

              ))}
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'block' } }}>
              {isLoggedIn ? (
                <Link to="/a" style={{ textDecoration: "none" }}>
                  <AppBarButton >Open Dashboard</AppBarButton>
                </Link>

              ) : (
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <AppBarButton >Log In</AppBarButton>
                </Link>
              )}

            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
}
export default ResponsiveAppBar;