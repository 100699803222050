import React from 'react';
import Typography from '@mui/material/Typography';
import LandingAppBar from './LandingAppBar';
import { useTheme } from '@mui/material/styles';
import Footer from './Footer';
import Section from './Section';


function LandingPageWeb() {
  const theme = useTheme();
  return (
    <>
      <LandingAppBar />
      <Section color={theme.palette.primary.main} >
        <Typography variant="h1" align="center" sx={{ paddingY: 4 }}>
          <strong>We'll be open soon!</strong>
        </Typography>
        <Typography variant="h6" align="center">
          BrewingNerd is working quickly to complete our closed beta.  Check back soon to follow our progress.
        </Typography>

      </Section>
      <Section>
        
      </Section>
      <Footer />
    </>
  );
}

export default LandingPageWeb;


