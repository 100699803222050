import { ColorUnitType, ConcentrationUnitType, GravityUnitType } from "./beerjson";
import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listFermentables: build.query<
      ListFermentablesApiResponse,
      ListFermentablesApiArg
    >({
      query: () => ({ url: `/v1/fermentables/` }),
      providesTags: ['Fermentable'],
    }),
    createFermentable: build.mutation<
      CreateFermentableApiResponse,
      CreateFermentableApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fermentables/`,
        method: "POST",
        body: queryArg.record,
      }),
      invalidatesTags: ['Fermentable']
    }),
    retrieveFermentable: build.query<
      RetrieveFermentableApiResponse,
      RetrieveFermentableApiArg
    >({
      query: (queryArg) => ({ url: `/v1/fermentables/${queryArg.id}/` }),
      providesTags: ["Fermentable"],
    }),
    updateFermentable: build.mutation<
      UpdateFermentableApiResponse,
      UpdateFermentableApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fermentables/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.record,
      }),
      invalidatesTags: ["Fermentable"],
    }),
    partialUpdateFermentable: build.mutation<
      PartialUpdateFermentableApiResponse,
      PartialUpdateFermentableApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fermentables/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.record,
      }),
    }),
    destroyFermentable: build.mutation<
      DestroyFermentableApiResponse,
      DestroyFermentableApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fermentables/${queryArg.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Fermentable"],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type ListFermentablesApiResponse = /** status 200  */ Fermentable[];
export type ListFermentablesApiArg = void;
export type CreateFermentableApiResponse = /** status 201  */ Fermentable;
export type CreateFermentableApiArg = {
  record: Fermentable;
};
export type RetrieveFermentableApiResponse = /** status 200  */ Fermentable;
export type RetrieveFermentableApiArg = {
  id: string;
};
export type UpdateFermentableApiResponse = /** status 200  */ Fermentable;
export type UpdateFermentableApiArg = {
  id: string;
  record: Fermentable;
};
export type PartialUpdateFermentableApiResponse =
  /** status 200  */ Fermentable;
export type PartialUpdateFermentableApiArg = {
  id: string;
  record: Fermentable;
};
export type DestroyFermentableApiResponse = unknown;
export type DestroyFermentableApiArg = {
  id: string;
};
export type Fermentable = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  public?: boolean;
  name: string;
  type:
    | "dry extract"
    | "extract"
    | "grain"
    | "sugar"
    | "fruit"
    | "juice"
    | "honey"
    | "other";
  origin?: string | null;
  producer?: string | null;
  product_id?: string | null;
  grain_group?:
    | (
        | "base"
        | "caramel"
        | "flaked"
        | "roasted"
        | "specialty"
        | "smoked"
        | "adjunct"
        | "acid"
      )
    | null;
  yield_fine_grind?: string | null;
  yield_coarse_grind?: string | null;
  yield_fine_coarse_difference?: string | null;
  yield_potential?: string | null;
  yield_potential_unit?: GravityUnitType | null;
  color_unit?: ColorUnitType | null;
  color?: string | null;
  notes?: string | null;
  alpha_amylase?: number | null;
  diastatic_power_unit?: string | null;
  diastatic_power?: number | null;
  protein?: string | null;
  kolbach_index?: number | null;
  max_in_batch?: string | null;
  recommend_mash?: boolean | null;
  glassy?: string | null;
  plump?: string | null;
  half?: string | null;
  mealy?: string | null;
  thru?: string | null;
  friability?: string | null;
  di_ph?: number | null;
  viscosity?: number | null;
  viscosity_unit?: string | null;
  dms_p?: number | null;
  dms_p_unit?: string | null;
  fan?: number | null;
  fan_unit?: string | null;
  fermentability?: string | null;
  beta_glucan?: number | null;
  beta_glucan_unit?: ConcentrationUnitType | null;
  overwrites?: string | null;
};
export const {
  useListFermentablesQuery,
  useLazyListFermentablesQuery,
  useCreateFermentableMutation,
  useRetrieveFermentableQuery,
  useLazyRetrieveFermentableQuery,
  useUpdateFermentableMutation,
  usePartialUpdateFermentableMutation,
  useDestroyFermentableMutation,
} = injectedRtkApi;
