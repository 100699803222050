import React from 'react';
import LandingPageMobile from './mobile/LandingPageMobile';
import LandingPageWeb from './web/LandingPageWeb';
import { isNative } from '../helpers/is-native';


function LandingPageRoot() {
  if (isNative()) {
    return (
      <LandingPageMobile />
    );
  }  
  return (
    <LandingPageWeb />
  );
}

export default LandingPageRoot;
