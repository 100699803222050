import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { AddRecord } from '../app/components/AppBar';

type ErrorProps = {
  setAddRecord?: (record: AddRecord | undefined) => void
}

export default function Error({setAddRecord}: ErrorProps) {
  useEffect(() => {
    setAddRecord && setAddRecord(undefined);
  }, [setAddRecord]);
  return (
    
      <div style={{ minWidth: "100vh", minHeight: "100vh", textAlign: "center", paddingTop: '45vh'}}>
      <Typography variant="h1">
        Oops!
      </Typography>
      <br/>
      <Typography variant="body1">
        An unexpected error has occurred.
      </Typography>
      </div>
    
  );
}

