import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createTokenObtainPair: build.mutation<
      CreateTokenObtainPairApiResponse,
      CreateTokenObtainPairApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/token/`,
        method: "POST",
        body: queryArg.tokenObtainPair,
      }),
    }),
    createTokenRefresh: build.mutation<
      CreateTokenRefreshApiResponse,
      CreateTokenRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/token/refresh/`,
        method: "POST",
        body: queryArg.tokenRefresh,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bnApi };
export type CreateTokenObtainPairApiResponse =
  /** status 201  */ TokenRefresh;
export type CreateTokenObtainPairApiArg = {
  tokenObtainPair: TokenObtainPair;
};
export type CreateTokenRefreshApiResponse = /** status 201  */ TokenRefresh;
export type CreateTokenRefreshApiArg = {
  tokenRefresh: TokenRefresh;
};
export type TokenObtainPair = {
  email: string;
  password: string;
};
export type TokenRefresh = {
  refresh: string;
  access?: string;
};
export const {
  useCreateTokenObtainPairMutation,
  useCreateTokenRefreshMutation,
} = injectedRtkApi;
